import {
    ConfigurationUploadStatus,
    ControllerProperties,
    ControllerProtocolSettings,
    Element,
} from '@priva/next-model';

export interface ControllersStateContainer {
    controllers: ControllersState;
}

export interface ControllerConfigurationUploadStatus {
    controllerId: string;
    status: ConfigurationUploadStatus;
}

export interface ControllersState {
    controllers: Element[];
    controllersProtocolSettings: ControllerProtocolSettings[];
    controllerProperties: ControllerProperties[];
}

export const initialControllersState: ControllersState = {
    controllers: [],
    controllersProtocolSettings: [],
    controllerProperties: [],
};
